<template>
  <div>
    <div class="info">
      <div class="top">
        <span class="title">《国战传说》账号注销</span>
      </div>
      <div class="delSuccess">
        <div class="van-image">
          <img src="@/assets/common/success.png" class="img">
        </div>
      </div>
      <div class="cont1">
        <h3 style="text-align: center">账号注销申请成功</h3>
        <p>您的游戏帐号将于{{currentDateTime}}完成注销。请注意，该游戏帐号注销有15天的冷静期。我们将在冷静期满后完成注销，删除或匿名化处理您的角色信息。</p>
        <p>请注意，在冷静期内，若您需要登录该游戏帐号，请您联系官方客服，同时将视为您撤回注销申请，您可以继续使用该账号登录游戏。</p>
      </div>
    </div>
    <van-form @submit="onSubmit" style="margin-left: 0.2rem;margin-top: -0.7rem">
      <van-button class="completeForm" type="danger">完成</van-button>
    </van-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      url: 'https://play.google.com/store/games',
      currentDateTime: ''
    };
  },
  mounted() {
    this.getCurrentDateTime();
  },
  methods: {
    getCurrentDateTime() {
      const date = new Date();
      date.setDate(date.getDate() + 15);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      this.currentDateTime = `${year}-${month}-${day} ${hours}:${minutes}`;
    },
    onSubmit() {
      //  同意后跳转到删除账号页面
      window.location.href = this.url
    }
  },
};
</script>

<style>

.info{
  margin: 0.2rem 0.2rem;
  border: 1px solid #333;
}
.top{
  font-size: 0.2rem;
  font-weight: bold;
  top: 10%;
  text-align: center;
  border-bottom: 2px solid #333;
}

.cont1{
  font-size: 0.15rem;
}

.completeForm {
  width: 94.5%;
  border-color: #02A7F0;
  background-color: #02A7F0;
  /*left: 2px;*/
}

.delSuccess {
  display: flex;
  justify-content: center;
  align-items: center;
}

.van-image {
  position: relative;
  display: inline-block;
  width: 20%;
}

.img {
  /* 可根据需要设置图片的样式 */
  max-width: 100%; /* 确保图片不超出容器宽度 */
  max-height: 100%; /* 确保图片不超出容器高度 */
}
</style>
